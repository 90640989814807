import React from 'react';
import './BasePages.css'

function Terms(props) {
    return (
        <div className="const_width terms-section">
            <div className="content_container">
                <p className="heading">Kullanım Şartları</p>
                <div className="mx-auto">
                    <p className="sub-heading">
                        <strong className="sub-heading-icon">1</strong>
                        Kullanım Şartları
                    </p>
                    <p className="content-text">
                        Bu Kullanım Şartları (bundan sonra <strong>‘KŞ’</strong>) <strong>‘Hizmet Sunucu’</strong>
                        (aşağıda açıklanmıştır) ve Web Sitesi
                        ve/veya Uygulamaya erişen (aşağıda açıklanmıştır) ve herhangi bir şekilde özelliklerinden ve
                        içeriklerinden yararlanan tüm taraflar (bundan sonra <strong>‘Ziyaretçiler’</strong>) arasındaki
                        ilişkinin
                        kurallarını belirlemekte ve bu ilişkiyi yönetmektedir. <br/><br/>
                        Hizmet Sunucu ve Ziyaretçiler bir arada <strong>'Tarafları'</strong> oluşturur.</p>
                </div>
                <div className="mx-auto">
                    <p className="sub-heading">
                        <strong className="sub-heading-icon">2</strong>
                        Genel
                    </p>
                    <p className="content-text">
                        Hizmet Sunucu tarafından FootyAssist.com web sitesi ve mobil uygulamaları (bundan sonra
                        <strong>‘Uygulama’</strong>) aracılığıyla sunulan herhangi veya tüm özellikler ve hizmetler ve
                        bunlarla ilgili
                        tüm bilgiler, materyaller ve linkler aşağıda belirtilen KŞ kapsamındadır. Hizmet Sunucu ile
                        yazılı bir şekilde aksi yönde anlaşılmadıysa, KŞ Uygulama tarafından sunulan tüm fonksiyonlar da
                        dahil olmak üzere Uygulamanın kullanımında Hizmet Sunucu ve Ziyaretçi arasındaki ilişkinin
                        kurallarını belirler.
                        Ziyaretçi Uygulamayı kullanmadan önce KŞ'nı dikkatle okumak ve anlamak zorundadır. Bir
                        Ziyaretçinin Uygulamayı kullanması başka herhangi bir şey yapmadan KŞ'ları okuduğu, anladığı ve
                        kabul ettiği anlamına gelir.
                        Hizmet Sunucu gerekli olduğu zaman KŞ'nı askıya alma, ekleme yapma, sonlandırma, değiştirme
                        ve/veya destekleme hakkını saklı tutar.
                        Hizmet Sunucu, Ziyaretçinin bu sayfaların içeriğini düzenli ve dikkatli olarak okumasını tavsiye
                        eder. Ziyaretçi Uygulamayı kullanarak KŞ'larına ve Ziyaretçinin farkında olup olmamasına
                        bakılmaksızın, yapılan en son değişikliklere tabi olmayı kabul etmiş olur.
                        Hizmet Sunucu tüm Kullanıcıların Uygulamayı güncellenmiş en son KŞ'na göre kullanıp
                        kullanmadığını doğrulamak zorunda değildir. KŞ'nın yürürlükteki versiyonu Uygulamada yayınlanan
                        versiyonudur.
                        Bu Uygulama sadece kanuni amaçlar için kullanılabilir. Uygulamanın, yürürlükteki herhangi bir
                        kanun veya düzenlemenin hilafına veya herhangi bir üçüncü tarafın haklarını çiğneyecek şekilde
                        herhangi bir materyalin aktarılması, dağıtımı, yayını veya saklanması için kullanılması
                        kesinlikle yasaktır. Buna (bununla sınırlı olmaksızın) uygulamanın bir telif hakkını, marka
                        hakkını, ticari sırrı veya başka bir entelektüel mülk hakkını ihlal edecek; müstehcen veya
                        küçüklere zararlı olacak veya yasadışı bir eylem olacak veya taciz olarak değerlendirilebilecek,
                        iftira veya karalama mahiyetinde, herhangi bir gizlilik veya veri koruma yasasını ihlal edecek
                        veya sahtekarlık mahiyetinde ve borsa kontrol veya kumar yasasını ihlal edecek mahiyette bir
                        amaç için Uygulamada veya Uygulama aracılığıyla bir materyalin aktarılması, dağıtımı, yayını
                        veya saklanması da dahildir.
                        Uygulamanın istenmeyen amaçlar için kullanımı ve/veya istismar edilmesi durumunda, Hizmet Sunucu
                        Ziyaretçiyi Uygulaman engelleme ve Ziyaretçinin adına açılmış olan her türlü hesabı kapatma
                        hakkını saklı tutar. Hizmet Sunucu tamamen kendi takdir hakkını kullanarak Ziyaretçiyi davet
                        etme hakkını saklı tutar.
                    </p>
                </div>
                <div className="mx-auto">
                    <p className="sub-heading">
                        <strong className="sub-heading-icon">3</strong>
                        Hizmetler
                    </p>
                    <p className="content-text">
                        Uygulama spor etkinlikleri hakkında canlı spor bilgileri, gerçek zamanlı spor skorları, maç
                        sonuçları, fikstür, kadro ve spor istatistikleri gibi bilgiler içeren etkileşimli web ve mobil
                        uygulaması/özellikleri sunmaktadır. Uygulamada yer alan sonuçlar ve diğer istatistikler diğer
                        bağımsız kaynaklar (üçüncü taraf) sunulan veya şirket için çalışma sonucu elde edilen veya diğer
                        resmi uygulamalar tarafından sunulan bilgileri yansıtır. Hizmet Sunucu Uygulamada gösterilen
                        içeriği, maç sonuçlarını ve diğer bilgileri düzenli olarak güncellemek için elinden geleni yapsa
                        da, Uygulamada görülen sonuçların teyit edilmesini kesinlikle tavsiye ederiz. Hizmet Sunucu,
                        Ziyaretçinin Uygulamada yer alan sonuçları ve diğer bilgileri kullanımından dolayı sorumlu
                        tutulamaz.
                    </p>
                </div>
                <div className="mx-auto">
                    <p className="sub-heading">
                        <strong className="sub-heading-icon">4</strong>
                        Üçüncü taraf web siteleri ve uygulamalar
                    </p>
                    <p className="content-text">
                        Ziyaretçi Uygulamayı ziyaret ettikten sonra üçüncü taraflarla istemli veya istemsiz olarak
                        kuracağı iletişimin ve bundan doğan herhangi bir sonucun Hizmet Sunucudan tamamen bağımsız
                        olduğunu ve bu iletişim doğrudan sonucu olarak doğacak olan herhangi bir anlaşma veya beklenti
                        ve diğer sonuçlardan Hizmet Sunucunun hiçbir şekilde sorumlu tutulamayacağını kabul eder.
                        Ziyaretçi ve böyle bir üçüncü taraf arasında doğabilecek herhangi bir anlaşmazlık veya
                        uyuşmazlık hiçbir şekilde Hizmet Sunucuyu içeremez.
                        Uygulama reklamlarında görünen üçüncü taraflar da dahil olmak üzere hiçbir üçüncü tarafın
                        Ziyaretçilerin Kişisel Verilerine veya Ziyaretçinin Hizmet Sunucuya vermiş olduğu başka herhangi
                        bir veriye erişimi yoktur.
                    </p>
                </div>
                <div className="mx-auto">
                    <p className="sub-heading">
                        <strong className="sub-heading-icon">5</strong>
                        Görsel/işitsel içerik
                    </p>
                    <p className="content-text">
                        Hizmet Sunucu Uygulama aracılığıyla görüntülenen harici Uygulamaların içeriğinden sorumlu
                        değildir. Uygulamadaki hiçbir video Hizmet Sunucunun sunucularında barındırılmamakta veya Hizmet
                        Sunucu tarafından oluşturulup sunucuya yüklenmemektedir.
                    </p>
                </div>
                <div className="mx-auto">
                    <p className="sub-heading">
                        <strong className="sub-heading-icon">6</strong>
                        Aktif olmayan hesaplar
                    </p>
                    <p className="content-text">
                        Bir Ziyaretçi Uygulamada bir hesap açtıysa ancak 60 gün boyunca bu hesaba erişmediyse, Hizmet
                        Sunucu bu hesabı daha önceden bilgilendirme yapmaksızın anında kapatma hakkını saklı tutar.
                    </p>
                </div>
                <div className="mx-auto">
                    <p className="sub-heading">
                        <strong className="sub-heading-icon">7</strong>
                        Entelektüel mülk
                    </p>
                    <p className="content-text">
                        Hizmet Sunucu tarafından daha önce herhangi bir yazılı yetkilendirme olmaksızın Ziyaretçiler
                        Uygulamadaki herhangi bir içeriği kopyalayamaz, değiştiremez, oynayamaz, dağıtamaz, iletemez,
                        görüntüleyemez, yeniden üretemez, transfer edemez, karşıya yükleyemez, indiremez veya başka
                        herhangi bir şekilde kullanıp değişiklik yapamaz.
                        Bu maddenin ihlali Avrupa Birliği yasaları ve diğer mevzuat kapsamında entelektüel mülk
                        haklarının ihlali anlamına gelir. Hizmet Sunucu ve onun tarafından yetkilendirilen herhangi bir
                        taraf, doğrudan veya dolaylı olarak bu ihlali yapan tarafa karşı yasa çerçevesinde hak arama
                        hakkını saklı tutar.
                    </p>
                </div>
                <div className="mx-auto">
                    <p className="sub-heading">
                        <strong className="sub-heading-icon">8</strong>
                        İlişki türü
                    </p>
                    <p className="content-text">
                        Bu KŞ Ziyaretçi ile Hizmet Sunucu arasında herhangi bir ortaklık, aracılık veya ortak teşebbüs
                        ilişkisi kurmaz.
                    </p>
                </div>
                <div className="mx-auto">
                    <p className="sub-heading">
                        <strong className="sub-heading-icon">9</strong>
                        KŞ’nın ihlali
                    </p>
                    <p className="content-text">
                        Ziyaretçi KŞ’nın herhangi bir maddesine uymazsa veya Hizmet Sunucu Ziyaretçinin KŞ’nın herhangi
                        bir maddesini doğrudan veya dolaylı bir şekilde ihlal ettiğine dair mantıklı şüpheleri varsa,
                        Hizmet Sunucu Ziyaretçiyi Uygulamadan yasaklamak veya Ziyaretçinin adına açılmış veya onunla
                        ilgili olan her türlü hesabı kapatma ve tamamen kendi takdir hakkını kullanarak Ziyaretçiye
                        karşı dava açma hakkını saklı tutar.
                    </p>
                </div>
                <div className="mx-auto">
                    <p className="sub-heading">
                        <strong className="sub-heading-icon">10</strong>
                        Yasaya uygunluk
                    </p>
                    <p className="content-text">
                        Ziyaretçilere ikamet ettikleri ve/veya bulundukları ve/veya vatandaşı oldukları ülkenin
                        mevzuatına uygun hareket etmeleri tavsiye edilir. Hizmet Sunucu, Uygulamanın kullanımı ile
                        ilgili olarak herhangi bir merci tarafından herhangi bir Ziyaretçiye karşı yapılan işlemlerden
                        dolayı herhangi bir sorumluluk kabul etmez.
                    </p>
                </div>
                <div className="mx-auto">
                    <p className="sub-heading">
                        <strong className="sub-heading-icon">11</strong>
                        Başlıklar
                    </p>
                    <p className="content-text">
                        Başlıklar bu ŞK okumanızı kolaylaştırmak ve daha anlaşılabilir kılmak için kullanılmaktadır.
                        Başlıklar, başlığı takip eden paragrafın içeriği hakkında bilgi vermek için kullanılmamaktadır.
                        Başlıklar hiçbir nedenle Hizmet Sunucuyu bağlamaz.
                    </p>
                </div>
                <div className="mx-auto">
                    <p className="sub-heading">
                        <strong className="sub-heading-icon">12</strong>
                        Feragatname
                    </p>
                    <p className="content-text">
                        Hizmet Sunucu tarafından Ziyaretçinin bu KŞ’nın herhangi bir hükmünü ihlal etmesi dolayısıyla
                        yapılan herhangi bir istisnanın, bu KŞ ile ilgili olarak gelecekte aynı veya başka türden bir
                        ihlal için de bir istisna yapılacağı anlamına gelmez.
                    </p>
                </div>
                <div className="mx-auto">
                    <p className="sub-heading">
                        <strong className="sub-heading-icon">13</strong>
                        Sorumluluk Reddi
                    </p>
                    <p className="sub-heading">
                        13.1 Beyan ve taahhütler
                    </p>
                    <p className="content-text">
                        Hizmet Sunucu, KŞ ile ilgili yürürlükteki mevzuatta açıkça belirtilenler hariç olmak üzere,
                        Uygulamanın içeriğinin doğru veya herhangi bir amaç için uygun odluğuna dair herhangi bir
                        taahhütte bulunmaz, söz veya garanti (doğrudan veya dolaylı olarak) vermez.
                        Ziyaretçi bu uygulamayı riski kabul ederek kullanır. Uygulama bir oyun veya kumar Uygulaması
                        değildir. Bu Uygulamanın sunucusu oyun veya kumar hizmetleri sunmamaktadır; dolayısıyla
                        oyuncuların paralarını bulundurmamakta ve herhangi bir oyun işlemi gerçekleştirmemektedir.
                        Uygulamada görüntülenen bahis oranları Uygulamanın barındırdığı bilgiler ve fonksiyonlardan
                        birisidir.
                        Hizmet Sunucu şunlar hakkında garanti vermez: -Uygulama tarafından sunulan fonksiyonların
                        yetkilendirilmiş olması, işlemlerin Ziyaretçiyi tatmin etmesi, hizmetin tamamen güvenli ve
                        hatasız olması, düzenli olarak güncellenmesi, her türlü yazılım hatasının düzenli olarak
                        düzeltildiği, kesintisiz hizmet verdiği, Uygulamanın hatasız ve virüssüz olduğu, sürekli çalışır
                        halde olduğu, yeterli oldukları, bilgilerin ve fonksiyonların güvenilir odlukları veya
                        Uygulamadan alınan bilgilerin ve kullanılan fonksiyonların yeterli ve güvenilir olduğu. Siteye
                        erişmeyi tercih eden kişiler bunu kendi inisiyatifleri ile yaparlar ve yerel yasalara uymaktan
                        ve yerel hukuk kurallarına uymaktan sorumludur.
                        Uygulama üçüncü taraf web sitelerine/uygulamalara/reklamlara/içeriğe linkler ve referanslar
                        içerebilir. Bu web siteleri/uygulamalar/reklamlar/içerik normalde web
                        siteleri/uygulamalar/reklamlar/içerikte yer alan farklı şart ve koşullara tabidir. Bunlar
                        Ziyaretçiye kolaylık sağlaması ve onun ilgisi için sunulur ve bu web
                        siteleri/uygulamalar/reklamlar/içerikte bulunan bilgilerle ilgili Hizmet Sunucunun herhangi bir
                        sorumluluğunu, onayını ima etmez. Hizmet Sunucu kendi etki alanında bulunmayan içerik veya
                        bilgi, metin veya grafiklerin doğruluğu ve ulaşılabilirliği ile ilgili olarak doğrudan veya
                        dolaylı olarak herhangi bir garanti vermez.
                    </p>
                    <p className="sub-heading">
                        13.2 Zarar veya kayıp
                    </p>
                    <p className="content-text">
                        Hizmet Sunucu bunlarla sınırlı olmamakla birlikte ticari zarar, avantajın kaybedilmesi,
                        beklenilen kazancın, kazanç veya diğer karın kaybedilmesi, işin kesintiye uğraması, ticari
                        bilgilerin kaybı veya diğer maddi veya nedensel zarar da dahil olmak üzere Uygulamanın
                        kullanımının sonucu olarak Ziyaretçinin veya bir üçüncü tarafın uğradığı zarardan sorumlu
                        değildir.
                        Hizmet Sunucu Uygulamada gösterilen bilginin kullanılmasından doğan üçüncü taraf web
                        siteleri/uygulamalarında uğranılan zararlardan veya elde edilen kazançlardan sorumlu değildir.
                        Üstteki iki maddenin genel geçerliğini ortadan kaldırmaksızın, aşağıdaki durumlarda herhangi bir
                        sorumluluk kabul edilmemektedir:
                    </p>
                    <ol>
                        <li>Yanlışlık, yanlış basım, yanlış anlamlandırma, yanlış duyma, yanlış okuma, yanış çeviri,
                            yazım hataları, okumada yanlışlıklar, işlem hataları, teknik riskler, kayıt hataları,
                            gösterme hataları, mücbir sebepler ve/veya diğer benzeri hata/yanlışlıklar;
                        </li>
                        <li>Hizmet Sunucu kurallarının ihlali;</li>
                        <li>Suç eylemleri;</li>
                        <li>Hizmet Sunucu tarafından verilen herhangi bir biçimdeki tavsiyeler;</li>
                        <li>Yasal eylemler veya diğer yasal yollar;</li>
                        <li>Ziyaretçilerin veya üçüncü tarafların Uygulamayı kullanmalarının sonucu olarak uğradıkları
                            zarar veya hasar, içerik veya Hizmet Sunucu tarafından tavsiye edilen herhangi bir link;
                        </li>
                        <li>
                            Ziyaretçilerin veya üçüncü tarafların Uygulamada yapılan değişiklikler veya hizmetin askıya
                            alınması veya hizmet kesintileri sonucu olarak uğradıkları zarar veya hasar;
                        </li>
                        <li>
                            Bir arıza, unutma veya Hizmet Sunucunun kontrolü dışındaki başka herhangi bir faktörden
                            kaynaklanan ve Uygulamanın veya içeriğinin herhangi bir kişi tarafından suça konu olacak
                            şekilde kullanılması;
                        </li>
                        <li>
                            Uygulamanın herhangi bir üçüncü taraf tarafından Ziyaretçinin Kullanıcı Adı ve Şifresi
                            kullanılarak giriş yapılması gereken özel alanlarına erişerek kullanılması;
                        </li>
                        <li>
                            Uygulamanın sunduğu hizmetler, fonksiyonlar veya diğer özelliklerde virüsler veya hatalardan
                            dolayı, bunların Uygulamanın diğer parametrelerinin tamamını etkilediğinden oluşan
                            farklılıklar ve bu farklılıklardan doğan her türlü hasar, maliyet, harcama, zarar veya
                            yükümlülük;
                        </li>
                        <li>
                            Ziyaretçilerin Uygulamaya erişim sağlamak için sözleşme yaptığı bir internet hizmet
                            sağlayıcısının veya başka herhangi bir üçüncü tarafın yaptığı herhangi bir eylem veya
                            eksiklik. İnternet hizmet sağlayıcı ve Ziyaretçi arasında dava açılırsa, Hizmet Sunucu bu
                            davanın bir parçası olamaz ve bu dava hiçbir şekilde bu KŞ’larını etkileyemez;
                        </li>
                        <li>
                            Hizmet Sunucu tarafından Uygulama için yetkilendirilmemiş başka bir üçüncü taraf veya başka
                            bir Ziyaretçi tarafından gönderilen materyalin içeriğinden dolayı bir Ziyaretçinin uğradığı
                            zarardan dolayı doğan talepler.
                        </li>
                    </ol>
                </div>
                <div className="mx-auto">
                    <p className="sub-heading">
                        <strong className="sub-heading-icon">14</strong>
                        Gizlilik Politikası
                    </p>
                    <p className="content-text">
                        FootyAssist.com web sitesi ve mobil uygulamaları (bundan sonra ‘Uygulama’) Uygulamaları
                        görüntüleyen veya başka herhangi bir şekilde kullanan tüm tarafların (bundan sonra
                        <strong>‘Ziyaretçiler’</strong>) gizliliğine saygı gösterir ve gizliliklerini korumak için
                        elinden geleni yapar.
                        Uygulama, Ziyaretçilere uygulama tarafından sunulan hizmetleri sunabilmek için ve sadece altta
                        belirtilen nedenlerle Ziyaretçileri hakkında ‘Kişisel Veri’ (altta açıklanmıştır) toplayabilir
                        ve kullanabilir.
                    </p>
                </div>
                <div className="mx-auto">
                    <p className="sub-heading">
                        <strong className="sub-heading-icon">15</strong>
                        Bu beyanın amacı
                    </p>
                    <p className="content-text">
                        Bu beyanın amacı:
                    </p>
                    <ul>
                        <li>Kontrolörün sizden hangi tür kişisel bilgileri toplayacağını ve bu kişisel bilgileri nasıl
                            kullanacağımızı belirlemek;
                        </li>
                        <li>
                            Kontrolör tarafından kişisel berilerin toplanmasının esaslarını belirlemek;
                        </li>
                        <li>
                            Kontrolörün kişisel bilgilerinizi nasıl işleyeceği hakkında sizi bilgilendirmek;
                        </li>
                        <li>
                            Kişisel bilgilerinizin kanuni ve sorumlu bir şekilde işlenmesi ile ilgili olarak veri koruma
                            düzenlemeleri çerçevesinde Kontrolörün sorumluluklarını açıklığa kavuşturmak;
                        </li>
                        <li>
                            Veri koruma haklarınız hakkında sizi bilgilendirmek.
                        </li>
                    </ul>
                    <p className="content-text">
                        Kişisel verilerinizi yürürlükteki veri koruma düzenlemeleri ve <strong>25 Mayıs 2018</strong>
                        tarihi itibarıyla
                        yürürlüğe giren Genel Veri Koruma Tüzüğü AB 2016/679 (“GDPR”) düzenlemelerine uygun ve kanuni
                        bir şekilde işliyoruz.
                    </p>
                </div>
                <div className="mx-auto">
                    <p className="sub-heading">
                        <strong className="sub-heading-icon">16</strong>
                        Kişisel Verilerin toplanması
                    </p>
                    <p className="content-text">
                        Bu Gizlilik Politikasında aksi belirtilmediği hallerde Uygulama, Ziyaretçilerin Uygulamayı
                        sadece görüntülediği zamanlarda herhangi bir Kişisel Veri toplamaz. Ancak, kayıt işleminden
                        sonra Uygulamada sunulan bazı ek veya gelişmiş hizmetlerden yararlanmak için Uygulama
                        Ziyaretçilerin bazı Kişisel Verilerine ihtiyaç duyar. Bu durumlarda Uygulama Ziyaretçilere,
                        Ziyaretçilerin Kişisel Verilerinin toplanması ve kullanılmasını kabul edip etmediklerini sorar.
                        Kayıttan sonra veya başka zamanlarda Uygulama Ziyaretçilerden kullanıcı adlarını (normalde bir
                        e-posta adresi) ve şifrelerini göndermelerini isteyebilir.
                        Ziyaretçiler Kişisel Bilgilerini sunmak veya Uygulama tarafından Kişisel Bilgilerinin
                        toplanmasına izin vermek zorunda değildir. Ancak, Uygulama, Kişisel Verilerinin toplanmasına
                        izin vermeyen Ziyaretçilere Uygulama tarafından sunulan tüm hizmetleri sunamayabilir.
                        Ziyaretçiler Uygulamanın Kişisel Verilerini toplamalarına daha önce izin vermelerine rağmen,
                        support@footyassist.com adresiyle iletişime geçerek daha sonra bu izni geri çekebilir. İznin
                        geri
                        çekilmesi durumunda kayıttan sonra sunulan ek veya gelişmiş hizmetler sunulamayacaktır.
                    </p>
                </div>
                <div className="mx-auto">
                    <p className="sub-heading">
                        <strong className="sub-heading-icon">17</strong>
                        Veri sahibi olarak haklarınız
                    </p>
                    <p className="sub-heading">
                        17.1 Erişim hakkı
                    </p>
                    <p className="content-text">
                        Ziyaretçiler Kontrolörden (burada açıklanmıştır) toplanan ve/veya kullanılan Kişisel Verileri
                        hakkında kişisel bilgi alma hakkına sahiptir. Bu talep Kontrolöre yazılı bir talep gönderilerek
                        iletilebilir (burada açıklanmıştır).
                        Uygulama toplanan Kişisel Verilerin güncel olması için elinden gelen çabayı gösterir. Ancak,
                        Ziyaretçiler, Uygulamanın bulundurduğu Kişisel Veriler hakkındaki değişiklikleri Uygulamaya
                        bildirmeye davet edilir.
                    </p>
                    <p className="sub-heading">
                        17.2 Verinin düzeltilmesi, engellenmesi veya silinmesi
                    </p>
                    <p className="content-text">
                        Kişisel Verilerinin doğru olmadığını düşünen Ziyaretçiler, Kontrolörden yazılı olarak verilerini
                        düzeltmesini isteyebilir. Ziyaretçiler ayrıca Kontrolörden yasa dışı bir şekilde kullanılması
                        halinde Kişisel Verilerinin engellenmesini veya silinmesini talep edebilir.
                    </p>
                    <p className="sub-heading">
                        17.3 İtiraz hakkı
                    </p>
                    <p className="content-text">
                        Bizimle istediğiniz zaman support@footyassist.com adresinden iletişime geçerek Kişisel
                        Bilgilerinizin
                        pazarlama amacıyla kullanılmamasını (örneğin bizden yaklaşan etkinlikler hakkında haberler,
                        haber bültenleri veya yayınlar alma) talep edebilirsiniz; bu durumda verileriniz bundan sonra bu
                        amaçlarla kullanılmayacaktır.
                    </p>
                    <p className="sub-heading">
                        17.4 İzni geri çekme hakkı
                    </p>
                    <p className="content-text">
                        Bu beyana ve burada açıklanan işleme uygulamalarına gösterdiğiniz rızayı ve verdiğiniz izni
                        istediğiniz zaman support@footyassist.com adresine eposta göndererek geri çekebilirsiniz. Bu,
                        geri
                        çekilmesinden önce izni temel alarak yürüttüğümüz işlemlerin yasallığını etkilemez. İznin geri
                        çekilmesi hizmetlerimizi anında kesmemize yol açacaktır.
                    </p>
                    <p className="sub-heading">
                        17.5 Düzeltme hakkı
                    </p>
                    <p className="content-text">
                        Ek bildirimlerde bulunmak yoluyla da dahil olmak üzere, hakkınızdaki doğru olmayan Kişisel
                        Verileri düzeltme, güncel olmayan her türlü bilgiyi güncelleme ve eksik Kişisel Verileri
                        tamamlama hakkına sahipsiniz.
                    </p>
                    <p className="sub-heading">
                        17.6 Silme hakkı
                    </p>
                    <p className="content-text">
                        Hakkınızda bulundurduğumuz Kişisel Verinin aşağıdaki artık bu veriye gereksinimin kalmadığı
                        durumlarda silinmesini isteyebilirsiniz:
                    </p>
                    <ul>
                        <li>
                            Kişisel Verinizi işlememiz için bize verdiğiniz izni geri çektiğinizde;
                        </li>
                        <li>
                            Kişisel Verinizin artık işlemesine gerek kalmadığında;
                        </li>
                        <li>
                            Kişisel Veriniz yasa dışı bir şekilde işlendiğinde.
                        </li>
                    </ul>
                    <p className="sub-heading">
                        17.7 İşlenmeyi kısıtlama hakkı
                    </p>
                    <p className="content-text">
                        Aşağıdaki durumlarda işleme faaliyetimizi kısıtlayabilirsiniz:
                    </p>
                    <ol>
                        <li>
                            Bu Kişisel Verinin doğru olmadığını iddia ettiğinizde, Kişisel Verinin doğruluğunu kontrol
                            etmemize izin verecek bir süre için;
                        </li>
                        <li>
                            İşlemin yasa dışı olarak değerlendirilmesi ve Kişisel Verinizin silinmesine karşı çıktığınız
                            ve bunun yerine kısıtlanmasını istediğinizde;
                        </li>
                        <li>
                            Burada açıklanan nedenlerle artık Kişisel Verinize ihtiyaç duymadığımız, ancak bunlara yasal
                            taleplerinizin belirlenmesi, uygulanması veya savunulması için ihtiyaç duyduğunuzda;
                        </li>
                        <li>
                            İşlemimize karşı çıkmanız durumunda, işlem faaliyetlerimizin yasal dayanaklarının sizinle
                            ilgili olanlara üstün olup olmadığının doğrulanmasını beklerken.
                        </li>
                    </ol>
                    <p className="sub-heading">
                        17.8 Verilerin taşınabilirliği hakkı
                    </p>
                    <p className="content-text">
                        25 Mayıs 2018 tarihi itibarıyla, Kişisel Verilerinizi yapılandırılmış ve makineler tarafından
                        okunabilir bir biçimde alma ve bu verileri başka bir Kontrolöre (GDPR belgesinde açıklanmıştır)
                        gönderme hakkına sahipsiniz.
                    </p>

                </div>
                <div className="mx-auto">
                    <p className="sub-heading">
                        <strong className="sub-heading-icon">18</strong>
                        Kişisel Verilerin toplanmasını ve kullanılmasının amaçları
                    </p>
                    <ol>
                        <li>
                            Ziyaretçilerle iletişim kurmak;
                        </li>
                        <li>
                            Ziyaretçilerin kişisel hesaplarına yeni şifre göndermek;
                        </li>
                        <li>
                            Uygulamayı bir dereceye kadar yapılandırma imkânı sunan bazı gelişmiş özellikler sunmak;
                        </li>
                        <li>
                            Uygulama tarafından sunulan içeriği geliştirmek;
                        </li>
                        <li>
                            Ziyaretçilere kişiselleştirilmiş bir Uygulama içeriği ve/veya tasarımı sunmak.
                        </li>
                    </ol>
                </div>
                <div className="mx-auto">
                    <p className="sub-heading">
                        <strong className="sub-heading-icon">19</strong>
                        Veri işlemenin yasal dayanakları
                    </p>
                    <p className="content-text">
                        Kişisel Verilerinizi ancak ve ancak bize izin vermeniz veya şu ana kadar açıkladığımız gibi size
                        hizmet sunmak ve/veya bu bildirimde açıklanan amaçlar için gerekli olması durumunda işliyoruz.
                        <br/><br/>
                            Ayrıca yasalar çerçevesinde herhangi bir hukuki zorunluluğu karşılamak için Kişisel
                            Verilerinizi
                            bir hukuki nedenle de işleyebiliriz. Bunların arasında yasal taleplerin savunulması veya
                            herhangi bir mahkeme veya yetkilinin emrine uyum sağlamak da bulunabilir.
                    </p>
                </div>
                <div className="mx-auto">
                    <p className="sub-heading">
                        <strong className="sub-heading-icon">20</strong>
                        Pazarlama
                    </p>
                    <p className="content-text">
                        Bu Uygulama aracılığıyla bize bilgilerinizi vermeniz ve bu bilgileri almayı tercih ederek
                        pazarlama bilgileri almak istemeniz durumunda bizden pazarlama konulu iletiler alacaksınız.
                        <br/><br/>
                            Kişisel Verilerinizi pazarlama amacıyla ve bunun için özel izniniz olmaksızın herhangi bir
                            üçüncü taraf ile paylaşmayacağız.
                    </p>
                </div>
                <div className="mx-auto">
                    <p className="sub-heading">
                        <strong className="sub-heading-icon">21</strong>
                        Kişisel Verilerin üçüncü taraflarla paylaşılması
                    </p>
                    <p className="content-text">
                        Hizmet Sunucu Ziyaretçilerin Kişisel Verisini daha önce bu konuda verilmiş bir izin olmadan
                        herhangi bir üçüncü tarafa satmaz, pazarlamaz veya kiralamaz veya başka bir şekilde paylaşmaz.
                        Ancak, Kişisel Veri Uygulamanın satılması durumunda üçüncü taraflarla paylaşılabilir.
                        <br/><br/>
                            Yukarıda açıklananlar, Uygulamanın Ziyaretçilerin Kişisel Verilerini üçüncü taraflarla
                            paylaşması hakkındaki herhangi bir yasal zorunlulukla ilgili hükümler saklı kalmak koşuluyla
                            geçerlidir.
                            <br/><br/>
                                Yukarıdakiler ayrıca Kişisel Verinin toplanması ve kullanılması için bir veya biden
                                fazla Amaç
                                için kesinlikle gerekli olan paylaşımlarla ilgili hükümler saklı kalmak koşuluyla
                                geçerlidir. Bu
                                durumda Uygulama ilgili Ziyaretçilerin iznini ister.
                                <br/><br/>
                                    Hizmet Sunucu Ziyaretçiler, satışlar, trafik ve Uygulamayla ilgili diğer istatistiki
                                    bilgilerle
                                    ilgili istatistikleri üçüncü taraflarla paylaşma hakkını saklı tutar, ancak bunlarda
                                    herhangi
                                    bir Ziyaretçiyle ilgili kimlik tanımlayıcı bilgi yer almaz.
                    </p>
                </div>
                <div className="mx-auto">
                    <p className="sub-heading">
                        <strong className="sub-heading-icon">22</strong>
                        Günlük dosyaları
                    </p>
                    <p className="content-text">
                        Uygulamayı daha iyi yönetmek ve genel kullanım için Ziyaretçiler hakkında geniş demografik bilgi
                        toplamak amacıyla, Uygulama tüm Ziyaretçilerin IP adreslerini ve her bir Ziyaretçi tarafından
                        ziyaret edilen sayfaları otomatik olarak kaydeder.
                    </p>
                </div>
                <div className="mx-auto">
                    <p className="sub-heading">
                        <strong className="sub-heading-icon">23</strong>
                        Çerez feragatnamesi
                    </p>
                    <p className="content-text">
                        “Çerez” bir web sunucusu tarafından Ziyaretçinin bilgisayarına kaydedilen bir bilgidir ve web
                        hizmetinin kişiselleştirilmesi için kullanılır. Uygulama çerezleri daha sonra bir fonksiyonun
                        çalıştırılması için ihtiyaç duyulabilecek Ziyaretçilerin eylemleri hakkında bilgi saklamak için
                        kullanır. Ziyaretçiler tarayıcı ayarlarından çerezleri devre dışı bırakabilir. Çerezleri içeriği
                        ve reklamları kişiselleştirmek, sosyal medya özellikleri sunmak ve trafiğimizi analiz etmek için
                        kullanıyoruz. Ayrıca Uygulama analiz ortaklarımız, bazı reklam ortaklarımız (programla ilgili)
                        ve sosyal medya (sadece ve sadece sosyal medya hesabınız ile giriş yaptığınızda) ile sitemizi
                        kullanımınız hakkında bilgi paylaşıyoruz. Ayrıntıları buradan inceleyebilirsiniz.
                        Bu bildirim Çerez Politikamız ve hakkınızda Kişisel Veri topladığımız ve kullandığımız
                        zamanlarda Kişisel Verinizi nasıl ve neden topladığımız hakkında sizi bilgilendirmek için
                        sizinle paylaştığımız diğer Gizlilik Uyarıları ile birlikte okunmalıdır.
                    </p>
                </div>
                <div className="mx-auto">
                    <p className="sub-heading">
                        <strong className="sub-heading-icon">24</strong>
                        Kişisel Verilerin üçüncü ülkelere transferi
                    </p>
                    <p className="content-text">
                        Kontrolör hiçbir Kişisel Veriyi Avrupa Ekonomik Bölgesi dışına transfer etmez ve buna mecbur
                        kalırsa Kişisel Verilerinizin yeterli biçimde korunduğundan emin olmak için gerekli tüm
                        tedbirlerin alındığından emin olur.
                    </p>
                </div>
                <div className="mx-auto">
                    <p className="sub-heading">
                        <strong className="sub-heading-icon">25</strong>
                        Güvenlik
                    </p>
                    <p className="content-text">
                        Uygulama ve Kontrolör iletişimin toplanan ve kullanılan Kişisel Verilerin yok olması,
                        kaybolması, kötü amaçlar için kullanılması ve değiştirilmesini engellemek için Ziyaretçinin
                        cihazı ile Hizmet Sunucunun sunucuları arasında Güvenli bağlantı “HTTPS” ile hem teknik hem de
                        kurumsal olarak çeşitli tedbirler almıştır. Buna ek olarak, belirlediğiniz tüm şifreler BCrypt
                        standardı ile saklanmaktadır. Bunlara rağmen, Hizmet Sunucu böyle bir eylemin
                        gerçekleşmeyeceğini garanti edemez.

                    </p>
                </div>
                <div className="mx-auto">
                    <p className="sub-heading">
                        <strong className="sub-heading-icon">26</strong>
                        Kişisel Verinin saklanacağı süre
                    </p>
                    <p className="content-text">
                        Kişisel Veri sadece toplanma amacının gerektirdiği süre boyunca saklanacaktır. Kullanıcının bir
                        (1) yıl boyunca aktif olmaması durumunda, toplanan tüm Kişisel Veri silinecektir.
                    </p>
                </div>
                <div className="mx-auto">
                    <p className="sub-heading">
                        <strong className="sub-heading-icon">27</strong>
                        Üçüncü taraf web siteleri/uygulamalar
                    </p>
                    <p className="content-text">
                        Üçüncü taraf web siteleri/uygulamalar bu Gizlilik Politikasının kapsamında değildir. Bu Uygulama
                        Ziyaretçilerin kolaylığı için diğer sitelere bazı linkler içerir. Uygulama üçüncü taraf web
                        siteleri/uygulamalarda gösterilen içerikten ve bunların gizliliğinizi korumak için aldığı
                        tedbirlerden sorumlu değildir. Ziyaretçilerin Uygulamadan eriştiği diğer web
                        siteleri/uygulamaların yeterli bir Gizlilik Politikası olmayabilir.
                    </p>
                </div>
                <div className="mx-auto">
                    <p className="sub-heading">
                        <strong className="sub-heading-icon">28</strong>
                        Gizlilik Politikasının kabul edilmesi
                    </p>
                    <p className="content-text">
                        Ziyaretçilerin Uygulama ve Kontrolör tarafından Kişisel Verilerinin toplanması ve kullanılmasına
                        verdiği izin yukarıda yer verilen Gizlilik Politikası kapsamındadır. Burada belirtilen şartları
                        ve maddeleri özümsemek ve zaman zaman Uygulama tarafından yapılabilecek değişiklikleri görmek
                        için Ziyaretçilerin Gizlilik Politikasını düzenli olarak okumaları tavsiye edilir.
                    </p>
                </div>
            </div>
        </div>
);
}

export default Terms;
