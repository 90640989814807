import React, {useContext} from 'react';
import {AppContext} from "../../context/AppContext";

function TrendMatchesTableTopScreen(props) {
    const {i18String} = useContext(AppContext);
    const {statsName, typeName} = props;

    const i18Titles = {
        title: `${i18String('Football Stats, Tables and Results')}`,
        statsText: `${i18String('matches_with_highest_stats', { statsName, typeName })}`,
        statsParagraph: `${i18String('teams_with_highest_stats_paragraph', { statsName, typeName })}`
    }

    return (
        <div className='trend-matches-table-top-content-container'>
            <h3 className='trend-matches-top-screen-titles'>{i18Titles.title}</h3>
            <h5 className='trend-matches-top-screen-titles'>{i18Titles.statsText}</h5>
            <p className='trend-matches-top-screen-stats-paragraph'>{i18Titles.statsParagraph}</p>
        </div>
    );
}

export default TrendMatchesTableTopScreen;
