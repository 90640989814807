import React, {useContext, useEffect, useState} from 'react';
import {fetchLeagueStandings} from "../../../api";
import LeagueStandingsTableAll from "./LeagueStandingsTableAll";
import LeagueStandingsTableHome from "./LeagueStandingsTableHome";
import LeagueStandingsTableAway from "./LeagueStandingsTableAway";
import {AppContext} from "../../../context/AppContext";

function LeagueStandings(props) {
    const {i18String} = useContext(AppContext);

    const {leagueStandingsData} = props;

    const [toggle, setToggle] = useState(0);

    const standingsTableButtons = [
        {
            buttonName: `${i18String('General')}`,
            toggle: 0
        },
        {
            buttonName: `${i18String('Home')}`,
            toggle: 1
        },
        {
            buttonName: `${i18String('Away')}`,
            toggle: 2
        }
    ]

    const activeTableButton = (btn) => {
        if (btn.toggle === toggle) {
            return 'standings-active-button'
        } else {
            return 'standings-button'
        }
    }

    const onClickTableButton = (btn) => {
        setToggle(btn.toggle)
    }

    return (
        <div className={'league-standings-table-section'}>
            <div className={'standings-table-button-container'}>
                {
                    standingsTableButtons.map(btn => {
                        return (
                            <div
                                key={btn.toggle}
                                onClick={() => onClickTableButton(btn)}
                                className={activeTableButton(btn)}
                            >
                                {btn.buttonName}
                            </div>
                        )
                    })
                }
            </div>

            {
                toggle === 0 && <LeagueStandingsTableAll i18String={i18String} leagueStandingsData={leagueStandingsData}/>
            }
            {
                toggle === 1 && <LeagueStandingsTableHome i18String={i18String} leagueStandingsData={leagueStandingsData}/>
            }
            {
                toggle === 2 && <LeagueStandingsTableAway i18String={i18String} leagueStandingsData={leagueStandingsData}/>
            }
        </div>
    );
}

export default LeagueStandings;
