import React, {useContext, useEffect, useState} from 'react';
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import GoalOverallTable from "./goal-stats-table/GoalOverallTable";
import {useParams} from "react-router-dom";
import {fetchLeagueDetail} from "../../../api";
import GoalHomeTable from "./goal-stats-table/GoalHomeTable";
import GoalAwayTable from "./goal-stats-table/GoalAwayTable";
import Gaol1HalfTable from "./goal-stats-table/Gaol1halfTable";
import Goal2HalfTable from "./goal-stats-table/Goal2HalfTable";
import GoalForTable from "./goal-stats-table/GoalForTable";
import GoalAgainstTable from "./goal-stats-table/GoalAgainstTable";
import CornerOverallTable from "./corner-stats-table/CornerOverallTable";
import CornerHomeTable from "./corner-stats-table/CornerHomeTable";
import CornerAwayTable from "./corner-stats-table/CornerAwayTable";
import Corner1HalfTable from "./corner-stats-table/Corner1HalfTable";
import Corner2HalfTable from "./corner-stats-table/Corner2HalfTable";
import CornerForTable from "./corner-stats-table/CornerForTable";
import CornerAgainstTable from "./corner-stats-table/CornerAgainstTable";
import CardOverallTable from "./card-stats-table/CardOverallTable";
import CardHomeTable from "./card-stats-table/CardHomeTable";
import CardAwayTable from "./card-stats-table/CardAwayTable";
import Card1HalfTable from "./card-stats-table/Card1HalfTable";
import Card2HalfTable from "./card-stats-table/Card2HalfTable";
import CardForTable from "./card-stats-table/CardForTable";
import CardAgainstTable from "./card-stats-table/CardAgainstTable";
import {AppContext} from "../../../context/AppContext";

function LeagueStats(props) {
    const {i18String} = useContext(AppContext);

    const {league} = props;

    const [selectedType, setSelectedType] = useState("goals");
    const [toggle, setToggle] = useState(0);


    const onClickTabsType = (tabType) => {
        setSelectedType(tabType.type);
        setToggle(tabType.toggle)
    }

    const statsTabsType = [
        {
            tabsName: i18String('Goals'),
            type: 'goals',
            toggle: 0
        },
        {
            tabsName: i18String('Corners'),
            type: 'corners',
            toggle: 1
        },
        {
            tabsName: i18String('Cards'),
            type: 'cards',
            toggle: 2
        },
    ]
    const subTabIndex = statsTabsType.findIndex((st) => st.type === selectedType)

    return (
        <div className={'league-stats-section'}>
            <div className={'league-stats-type-tabs-container'}>
                <Tabs
                    className={'league-detail-stats-container'}
                    value={subTabIndex}
                    TabIndicatorProps={{
                        style: {
                            backgroundColor: "#00b1ff"
                        }
                    }}
                >
                    {
                        statsTabsType.map((tabTitle, index) => {
                            return (<Tab
                                key={tabTitle.type}
                                className={'league-details-tab-item'}
                                onClick={() => onClickTabsType(tabTitle)}
                                value={index}
                                label={tabTitle.tabsName}
                            />)
                        })
                    }
                </Tabs>
            </div>

            {
                !!league.seasonId &&
                <div>
                    {
                        toggle === 0 &&
                        <div>
                            <GoalOverallTable i18String={i18String} league={league} type={'GoalsOverall'}/>
                            <GoalHomeTable i18String={i18String} league={league} type={'GoalsHome'}/>
                            <GoalAwayTable i18String={i18String} league={league} type={'GoalsAway'}/>
                            <Gaol1HalfTable i18String={i18String} league={league} type={'Goals1Half'}/>
                            <Goal2HalfTable i18String={i18String} league={league} type={'Goals2Half'}/>
                            <GoalForTable i18String={i18String} league={league} type={'GoalsFor'}/>
                            <GoalAgainstTable i18String={i18String} league={league} type={'GoalsAgainst'}/>
                        </div>
                    }
                    {
                        toggle === 1 &&
                        <div>
                            <CornerOverallTable i18String={i18String} league={league} type={'CornersOverall'}/>
                            <CornerHomeTable i18String={i18String} league={league} type={'CornersHome'}/>
                            <CornerAwayTable i18String={i18String} league={league} type={'CornersAway'}/>
                            <Corner1HalfTable i18String={i18String} league={league} type={'Corners1Half'}/>
                            <Corner2HalfTable i18String={i18String} league={league} type={'Corners2Half'}/>
                            <CornerForTable i18String={i18String} league={league} type={'CornersFor'}/>
                            <CornerAgainstTable i18String={i18String} league={league} type={'CornersAgainst'}/>
                        </div>
                    }
                    {
                        toggle === 2 &&
                        <div>
                            <CardOverallTable i18String={i18String} league={league} type={'CardsOverall'}/>
                            <CardHomeTable i18String={i18String} league={league} type={'CardsHome'}/>
                            <CardAwayTable i18String={i18String} league={league} type={'CardsAway'}/>
                            <Card1HalfTable i18String={i18String} league={league} type={'Cards1Half'}/>
                            <Card2HalfTable i18String={i18String} league={league} type={'Cards2Half'}/>
                            {/*<CardForTable league={league} type={'CardsFor'}/>
                    <CardAgainstTable league={league} type={'CardsAgainst'}/>*/}
                        </div>
                    }
                </div>
            }

        </div>
    );
}

export default LeagueStats;
