/*export const timeZoneLists = [
    {text: 'İstanbul', value: 'Europe/Istanbul'},
    {text: 'London', value: 'Europe/London'},
    {text: 'Paris', value: 'Europe/Paris'},
    {text: 'Madrid', value: 'Europe/Madrid'},
]*/

export let timeZoneLists = [
    { text: '(GMT-11:00) Midway Island', value: 'Pacific/Midway' },
    { text: '(GMT-11:00) Samoa', value: 'US/Samoa' },
    { text: '(GMT-10:00) Hawaii', value: 'US/Hawaii' },
    { text: '(GMT-09:00) Alaska', value: 'US/Alaska' },
    { text: '(GMT-08:00) Pacific Time (US & Canada)', value: 'US/Pacific' },
    { text: '(GMT-08:00) Tijuana', value: 'America/Tijuana' },
    { text: '(GMT-07:00) Arizona', value: 'US/Arizona' },
    { text: '(GMT-07:00) Mountain Time (US & Canada)', value: 'US/Mountain' },
    { text: '(GMT-07:00) Chihuahua', value: 'America/Chihuahua' },
    { text: '(GMT-07:00) Mazatlan', value: 'America/Mazatlan' },
    { text: '(GMT-06:00) Mexico City', value: 'America/Mexico_City' },
    { text: '(GMT-06:00) Monterrey', value: 'America/Monterrey' },
    { text: '(GMT-06:00) Saskatchewan', value: 'Canada/Saskatchewan' },
    { text: '(GMT-06:00) Central Time (US & Canada)', value: 'US/Central' },
    { text: '(GMT-05:00) Eastern Time (US & Canada)', value: 'US/Eastern' },
    { text: '(GMT-05:00) Indiana (East)', value: 'US/East-Indiana' },
    { text: '(GMT-05:00) Bogota', value: 'America/Bogota' },
    { text: '(GMT-05:00) Lima', value: 'America/Lima' },
    { text: '(GMT-04:30) Caracas', value: 'America/Caracas' },
    { text: '(GMT-04:00) Atlantic Time (Canada)', value: 'Canada/Atlantic' },
    { text: '(GMT-04:00) La Paz', value: 'America/La_Paz' },
    { text: '(GMT-04:00) Santiago', value: 'America/Santiago' },
    { text: '(GMT-03:30) Newfoundland', value: 'Canada/Newfoundland' },
    { text: '(GMT-03:00) Buenos Aires', value: 'America/Buenos_Aires' },
    { text: '(GMT-02:00) Stanley', value: 'Atlantic/Stanley' },
    { text: '(GMT-01:00) Azores', value: 'Atlantic/Azores' },
    { text: '(GMT-01:00) Cape Verde Is.', value: 'Atlantic/Cape_Verde' },
    { text: '(GMT) Casablanca', value: 'Africa/Casablanca' },
    { text: '(GMT) Dublin', value: 'Europe/Dublin' },
    { text: '(GMT) Lisbon', value: 'Europe/Lisbon' },
    { text: '(GMT) London', value: 'Europe/London' },
    { text: '(GMT) Monrovia', value: 'Africa/Monrovia' },
    { text: '(GMT+01:00) Amsterdam', value: 'Europe/Amsterdam' },
    { text: '(GMT+01:00) Belgrade', value: 'Europe/Belgrade' },
    { text: '(GMT+01:00) Berlin', value: 'Europe/Berlin' },
    { text: '(GMT+01:00) Bratislava', value: 'Europe/Bratislava' },
    { text: '(GMT+01:00) Brussels', value: 'Europe/Brussels' },
    { text: '(GMT+01:00) Budapest', value: 'Europe/Budapest' },
    { text: '(GMT+01:00) Copenhagen', value: 'Europe/Copenhagen' },
    { text: '(GMT+01:00) Ljubljana', value: 'Europe/Ljubljana' },
    { text: '(GMT+01:00) Madrid', value: 'Europe/Madrid' },
    { text: '(GMT+01:00) Paris', value: 'Europe/Paris' },
    { text: '(GMT+01:00) Prague', value: 'Europe/Prague' },
    { text: '(GMT+01:00) Rome', value: 'Europe/Rome' },
    { text: '(GMT+01:00) Sarajevo', value: 'Europe/Sarajevo' },
    { text: '(GMT+01:00) Skopje', value: 'Europe/Skopje' },
    { text: '(GMT+01:00) Stockholm', value: 'Europe/Stockholm' },
    { text: '(GMT+01:00) Vienna', value: 'Europe/Vienna' },
    { text: '(GMT+01:00) Warsaw', value: 'Europe/Warsaw' },
    { text: '(GMT+01:00) Zagreb', value: 'Europe/Zagreb' },
    { text: '(GMT+02:00) Athens', value: 'Europe/Athens' },
    { text: '(GMT+02:00) Bucharest', value: 'Europe/Bucharest' },
    { text: '(GMT+02:00) Cairo', value: 'Africa/Cairo' },
    { text: '(GMT+02:00) Harare', value: 'Africa/Harare' },
    { text: '(GMT+02:00) Helsinki', value: 'Europe/Helsinki' },
    { text: '(GMT+03:00) Istanbul', value: 'Europe/Istanbul' },
    { text: '(GMT+02:00) Jerusalem', value: 'Asia/Jerusalem' },
    { text: '(GMT+02:00) Kyiv', value: 'Europe/Kiev' },
    { text: '(GMT+02:00) Minsk', value: 'Europe/Minsk' },
    { text: '(GMT+02:00) Riga', value: 'Europe/Riga' },
    { text: '(GMT+02:00) Sofia', value: 'Europe/Sofia' },
    { text: '(GMT+02:00) Tallinn', value: 'Europe/Tallinn' },
    { text: '(GMT+02:00) Vilnius', value: 'Europe/Vilnius' },
]
