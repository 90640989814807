export const i18n = {
    en: {
        header: {
            signIn: 'Sign In',
            signUp: 'Sign Up',
            logout: 'Logout',
            password: 'Password',
            forgotPassword: 'Forgot password?',
            nameAndSurname: 'Name Surname',
            userName: 'User Name',
            loginError: 'Loggin Error',
            loginErrorText: 'Check username or password',
            loginSuccess: 'Success',
            loginSuccessText: 'Login successful',
            enterName: 'Please enter name.',
            enterUserName: 'Please enter User Name.',
            enterMail: 'Please enter Email.',
            enterPassword: 'Please enter Password.',
            doesntMatchConfirmPassword: 'Password and Confirm Password does not match.',
            confirmPassword: 'Please enter Confirm Password.',
            doesntMatchPassword: 'Passwords doesn\'t match.',
            emailAddress: 'E-Mail'

        },
        generalForSite: {
            live: 'Live',
            goalTab: 'Goal',
            cornerTab: 'Corner',
            cardTab: 'Card',
            AvgTab: 'Avg',
            refereeTab: 'Referee',
            filterText: 'Filter',
            allText: 'All',
            today: 'Today',
            loading: 'Loading...'
        },
        fixturesScreen: {}
    },
    tr: {
        header: {
            signIn: 'Giriş Yap',
            signUp: 'Kayıt Ol',
            logout: 'Çıkış',
            password: 'Şifre',
            forgotPassword: 'Şifremi unutum?',
            nameAndSurname: 'Ad Soyad',
            userName: 'Kullanıcı Adı',
            loginError: 'Giriş Hatalı',
            loginErrorText: 'Kullanıcı adınızı veya şifrenizi kontrol ediniz',
            loginSuccess: 'Başarılı',
            loginSuccessText: 'Giriş başarılı',
            enterName: 'Lütfen isminizi giriniz.',
            enterUserName: 'Lütfen kullanıcı adınızı giriniz.',
            enterMail: 'Lütfen Mail adresinizi giriniz.',
            enterPassword: 'Lütfen şifrenizi giriniz.',
            doesntMatchConfirmPassword: 'Şifre ve şifre onaylama eşleşmiyor.',
            confirmPassword: 'Lütfen şifrenizi doğrulayınız.',
            doesntMatchPassword: 'Passwords doesn\'t match.',
            emailAddress: 'E-Mail Adresi'

        },
        generalForSite: {
            live: 'Canlı',
            goalTab: 'Gol',
            cornerTab: 'Korner',
            cardTab: 'Kart',
            AvgTab: 'Avg',
            refereeTab: 'Hakem',
            filterText: 'Filtrele',
            allText: 'Hepsi',
            today: 'Bugün',
            loading: 'Yükleniyor...'
        },
        fixturesScreen: {}
    }
}

export const en = {
    header: {
        signIn: 'Sign In',
        signUp: 'Sign Up',
        logout: 'Logout',
    },
    fixturesScreen: {}
}
