import React, {useContext} from 'react';
import {Link, useParams} from "react-router-dom";
import dayjs from "dayjs";
import {AppContext} from "../../context/AppContext";

function UserAccount(props) {
    const {i18String} = useContext(AppContext);

    const params = useParams();
    const userDetail = localStorage.getItem('user');
    const user = JSON.parse(userDetail);

    const startTime = (value) => {
        return dayjs(value).format("YYYY/MM/DD ddd HH:hh")
    }

    return (
        <div className='const-width'>
            <div className='user-account-detail-section'>
                <h3 className='user-name'>@{params.username}</h3>

                <div>
                    {
                        user &&
                        <div>
                            <div className='user-label-container'>
                                <span className='user-label'>{i18String('Name')}: </span>
                                <span className='user-label-text'>{user.name}</span>
                            </div>
                            <div className='user-label-container'>
                                <span className='user-label'>{i18String('E-Mail')}: </span>
                                <span className='user-label-text'>{user.email}</span>
                            </div>
                            <div className='user-label-container'>
                                <span className='user-label'>{i18String('Date Of Registration')}: </span>
                                <span className='user-label-text'>{startTime(user.dateCreated)}</span>
                            </div>
                            <div className='user-label-container'>
                                <span className='user-label'>{i18String('Account Type')}: </span>
                                <span className='user-label-text'>{user.premium && user.premium.status !== 'canceled' ? 'Premium' : `Basic `}</span>
                            </div>

                            {/*{
                                user.premium === null &&
                                <div className='premium-button-container'>
                                    <Link className='join-premium-button' to={'/join-premium'}>join Premium</Link>
                                </div>
                            }*/}

                            {
                                user.premium && user.premium.status === 'active' &&
                                <Link className='unsubscription-text' to={`/account-detail/${user.username}/cancel-subscription`}>
                                    <span>{i18String('I want to unsubscribe from Premium.')}</span>
                                </Link>
                            }
                        </div>
                    }
                </div>
            </div>
        </div>
    );
}

export default UserAccount;
