import React, {useContext, useState} from 'react';
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import {AppContext} from "../../context/AppContext";
import {i18n} from "../../i18n/i18n";

function SignUp(props) {

    const {register, lang, i18String} = useContext(AppContext);

    const [input, setInput] = useState({
        name: '',
        username: '',
        email: '',
        password: '',
        confirmPassword: ''
    });

    const [error, setError] = useState({
        name: '',
        username: '',
        email: '',
        password: '',
        confirmPassword: ''
    })

    const onInputChange = e => {
        const {name, value} = e.target;
        setInput(prev => ({
            ...prev,
            [name]: value
        }));
        validateInput(e);
    }

    const validateInput = e => {
        let {name, value} = e.target;
        setError(prev => {
            const stateObj = {...prev, [name]: ""};

            switch (name) {
                case "name":
                    if (!value) {
                        stateObj[name] = i18String('Please enter Name');
                    }
                    break;

                case "username":
                    if (!value) {
                        stateObj[name] = i18String('Please enter User Name');
                    }
                    break;

                case "email":
                    if (!value) {
                        stateObj[name] = i18String('Please enter E-Mail');
                    }
                    break;

                case "password":
                    if (!value) {
                        stateObj[name] = i18String('Please enter Password');
                    } else if (input.confirmPassword && value !== input.confirmPassword) {
                        stateObj["confirmPassword"] = stateObj[name] = i18String('Password and Confirm Password does not match.');
                    } else {
                        stateObj["confirmPassword"] = input.confirmPassword ? "" : error.confirmPassword;
                    }
                    break;

                case "confirmPassword":
                    if (!value) {
                        stateObj[name] = i18String('Please enter Confirm Password.');
                    } else if (input.password && value !== input.password) {
                        stateObj[name] = stateObj[name] = i18String('Passwords doesn\'t match.');
                    }
                    break;

                default:
                    break;
            }

            return stateObj;
        });
    }

    return (
        <div className={'general-auth-section'}>
            <Container component="main" maxWidth="sm">
                <Box
                    sx={{
                        boxShadow: 3,
                        borderRadius: 2,
                        px: 4,
                        py: 6,
                        marginTop: 8,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                >
                    <Typography component="h1" variant="h5">
                        <div>
                            <span className='auth-text'>{i18String('Register')}</span>
                        </div>
                    </Typography>
                    <Box component="form" onSubmit={register} noValidate sx={{mt: 1}}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="name"
                            label={`${i18String('Name Surname')}`}
                            id="name"
                            autoComplete="name"
                            autoFocus
                            onChange={onInputChange}
                            onBlur={validateInput}
                        />
                        {error.name && <span className='register-error-text'>{error.name}</span>}
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="username"
                            label={`${i18String('User Name')}`}
                            id="username"
                            autoComplete="username"
                            onChange={onInputChange}
                            onBlur={validateInput}
                        />
                        {error.username && <span className='register-error-text'>{error.username}</span>}
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label={`${i18String('Email')}`}
                            name="email"
                            autoComplete="email"
                            onChange={onInputChange}
                            onBlur={validateInput}
                        />
                        {error.email && <span className='register-error-text'>{error.email}</span>}
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label={`${i18String('Password')}`}
                            type="password"
                            id="password"
                            value={input.password}
                            autoComplete="current-password"
                            onChange={onInputChange}
                            onBlur={validateInput}
                        />
                        {error.password && <span className='register-error-text'>{error.password}</span>}
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="confirmPassword"
                            label={`${i18String('Please Enter Confirm Password')}`}
                            type="password"
                            value={input.confirmPassword}
                            id="confirmPassword"
                            onChange={onInputChange}
                            onBlur={validateInput}
                        />
                        {error.confirmPassword && <span className='register-error-text'>{error.confirmPassword}</span>}
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{mt: 3, mb: 2}}
                            style={{background: 'var(--H3Dark3)'}}
                        >
                            <span>{i18String('Register')}</span>
                        </Button>
                    </Box>
                </Box>
            </Container>
        </div>
    );
}

export default SignUp;
