import React, {useContext, useEffect, useState} from 'react';
import {fetchBestTeams} from "../../api";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import './BestTeams.css'
import dayjs from "dayjs";
import {AppContext} from "../../context/AppContext";
import padLock from "../../assets/icons/padlock.png";

function BestTeamsContent(props) {
  const {isPro, lang, i18String} = useContext(AppContext);

  const [bestTeamsData, setBestTeamsData] = useState(null);

  useEffect(() => {
    if (props.type) {
      fetchBestTeams(props.type).then((data) => {
        setBestTeamsData(data.data.data)
      })
    }
  }, []);

  const tableHeader = [
    {text: `${i18String('Team')}`, value: 'teamName', align: 'start', sortable: false, width: '25%'},
    {text: `${i18String('Next Match')}`, value: 'dateTimeUtc', sortable: false, width: '15%', align: 'center'},
    {text: `${i18String('VS Team')}`, value: 'vsTeamName', sortable: false, width: '20%', align: 'center'},
    {text: `${i18String('Matches')}`, value: 'played', width: '10%', align: 'center'},
    {text: '%', value: 'percent', width: '10%', align: 'center'},
  ]

  const mobileTableHeader = [
    {text: `${i18String('Team')}`, value: 'teamName', align: 'start', sortable: false, width: '25%'},
    {text: `${i18String('Matches')}`, value: 'played', width: '10%', align: 'center'},
    {text: '%', value: 'percent', width: '10%', align: 'center'},
  ]

  const statsColor = (stats) => {
    if (stats > 90) {
      return {
        background: '#72da2373',
        color: '#095035'
      }
    } else if (stats > 80) {
      return {
        background: '#72da233d',
        color: '#095035'
      }
    } else if (stats > 50) {
      return {
        background: '#f6f7cd',
        color: '#656500'
      }
    } else {
      return {
        background: '#ffe9e6',
        color: '#ff7563'
      }
    }
  }

  const matchDate = (value) => {
    const today = new Date().toISOString().substr(0, 10);
    const nextDay = dayjs(today).add(1, 'days').format('YYYY-MM-DD');
    const getDate = new Date(value).toISOString().substr(0, 10);

    if (today === getDate) {
      return `${i18String('Today')}`
    } else if (getDate === nextDay) {
      if (lang === 'en') {
        return 'Tomorrow'
      } else {
        return 'Yarın'
      }
    } else {
      return dayjs(value).format("ddd DD MMM ");
    }
  }

  return (
    <div>
      {
        bestTeamsData !== null ?
          <div>
            {/*WEB VIEW*/}
            <div className={'best-teams-web-view'}>
              <TableContainer>
                <Table className="best-teams-table-section" size={"medium"}
                       sx={{minWidth: 250}}
                       aria-label="simple table">
                  <TableHead acitve={true}>
                    <TableRow className="best-teams-table-headers">
                      {
                        tableHeader.map((header) => {
                          return (
                              <TableCell sortDirection={header.text}> {header.text}</TableCell>
                          )
                        })
                      }
                    </TableRow>
                  </TableHead>
                  { bestTeamsData && bestTeamsData.map((teamStat,rowIndex) => {
                    return (
                        <TableBody className="table-body-section">
                          {
                            rowIndex > 10 && !isPro ?
                                <TableRow
                                    key={teamStat.id}
                                    className="best-team-locked-row"
                                    style ={ rowIndex % 2? { background : 'var(--H3Light1)' }:{ background : "var(--H3Light0)" }}
                                    sx={{
                                      padding: '10px 0'
                                    }}
                                >
                                  <TableCell size="small" padding="normal" align="center">
                                    <img className='pad-lock-icon' src={padLock} alt=""/>
                                  </TableCell>

                                  <TableCell size="small" padding="normal" align="center">
                                    <img className='pad-lock-icon' src={padLock} alt=""/>
                                  </TableCell>

                                  <TableCell size="small" padding="normal" align="center">
                                    <img className='pad-lock-icon' src={padLock} alt=""/>
                                  </TableCell>

                                  <TableCell size="small" padding="normal" align="center">
                                    <img className='pad-lock-icon' src={padLock} alt=""/>
                                  </TableCell>

                                  <TableCell size="small" padding="normal" align="center">
                                    <img className='pad-lock-icon' src={padLock} alt=""/>
                                  </TableCell>
                                </TableRow>
                                :
                                <TableRow
                                    key={teamStat.id}
                                    className="best-team-content-row"
                                    style ={ rowIndex % 2? { background : "var(--H3Light1)" }:{ background : "var(--H3Light0)" }}
                                >
                                  <TableCell size="small" padding="normal" align="left">
                                    <div className="team-container">
                                      <span className="best-teams-name">{teamStat.teamName}</span>
                                    </div>
                                  </TableCell>

                                  <TableCell size="small" padding="normal" align="center">
                                    <span className="best-teams-date-time-and-btts-texts">{matchDate(teamStat.dateTimeUtc)}</span>
                                  </TableCell>

                                  <TableCell size="small" padding="normal" align="center">
                                    <span className="best-teams-name">{teamStat.vsTeamName}</span>
                                  </TableCell>

                                  <TableCell size="small" padding="normal" align="center">
                                    <div className="played-container">
                              <span><span className="best-teams-played-cont-texts  best-teams-date-time-and-btts-texts">{teamStat.total} </span><span
                                  className="best-teams-played-cont-texts-2  best-teams-date-time-and-btts-texts"> / {teamStat.played}</span></span>
                                    </div>
                                  </TableCell>

                                  <TableCell size="small" padding="normal" align="center">
                                    <div className="percent-cell-container" style={statsColor(teamStat.percent)}>
                                      <span className="percent-text">{teamStat.percent} %</span>
                                    </div>
                                  </TableCell>
                                </TableRow>
                          }
                        </TableBody>
                    )
                  })
                  }
                </Table>
              </TableContainer>
            </div>

            {/*MOBILE VIEW*/}
            <div className={'best-teams-mobile-view'}>
              <TableContainer>
                <Table className="best-teams-table-section"
                       aria-label="simple table">
                  <TableHead>
                    <TableRow className="best-teams-table-headers">
                      {
                        mobileTableHeader.map((header) => {
                          return (
                              <TableCell sortDirection={header.text}> {header.text}</TableCell>
                          )
                        })
                      }
                    </TableRow>
                  </TableHead>
                  { bestTeamsData && bestTeamsData.map((teamStat,rowIndex) => {
                    return (
                        <TableBody className="table-body-section">
                          {
                            rowIndex > 10 && !isPro ?
                                <TableRow
                                    key={teamStat.id}
                                    className="best-team-locked-row"
                                    style ={ rowIndex % 2? { background : "var(--H3Light1)" }:{ background : "var(--H3Light0)" }}
                                >
                                  <TableCell size="small" padding="normal" align="center">
                                    <img className='pad-lock-icon' src={padLock} alt=""/>
                                  </TableCell>

                                  <TableCell size="small" padding="normal" align="center">
                                    <img className='pad-lock-icon' src={padLock} alt=""/>
                                  </TableCell>

                                  <TableCell size="small" padding="normal" align="center">
                                    <img className='pad-lock-icon' src={padLock} alt=""/>
                                  </TableCell>
                                </TableRow>
                                :
                                <TableRow
                                    key={teamStat.id}
                                    className="best-team-content-row"
                                    style ={ rowIndex % 2? { background : "var(--H3Light1)" }:{ background : "var(--H3Light0)" }}
                                >
                                  <TableCell size="small" padding="normal" align="left">
                                    <div className="team-container">
                                      <div className={'best-teams-match-teams-container'}>
                                        <div>
                                          <span className="best-teams-name">{teamStat.teamName}</span>
                                        </div>
                                        <div className='best-teams-vs-teams-and-date-time'>
                                          <span className="best-teams-vs-teams">vs {teamStat.vsTeamName}</span>
                                          <span> | </span>
                                          <span className="best-teams-date-time-for-mobile">{matchDate(teamStat.dateTimeUtc)}</span>
                                        </div>
                                      </div>
                                    </div>
                                  </TableCell>

                                  <TableCell size="small" padding="normal" align="center">
                                    <div className="played-container">
                              <span><span className="best-teams-played-cont-texts">{teamStat.total} </span><span
                                  className="best-teams-played-cont-texts-2"> / {teamStat.played}</span></span>
                                    </div>
                                  </TableCell>

                                  <TableCell size="small" padding="normal" align="center">
                                    <div className="percent-cell-container" style={statsColor(teamStat.percent)}>
                                      <span className="percent-text">{teamStat.percent}</span>
                                    </div>
                                  </TableCell>
                                </TableRow>
                          }
                        </TableBody>
                    )
                  })
                  }
                </Table>
              </TableContainer>
            </div>
          </div>
          :
          <div className="const_width loading-container">
            <div>
              <div className="loading blink"></div>
              <div>
                Loading...
              </div>
            </div>
          </div>
      }
    </div>
  );
}

export default BestTeamsContent;
