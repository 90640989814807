import React, {useEffect, useState} from 'react';
import img from "../../../assets/icons/instagram.png";
import liveIcon from "../../../assets/icons/clock.png";
import logo from "../../../assets/icons/logo.png";
import searchIcon from "../../../assets/icons/searchIcon.png";
import calenderIcon from "../../../assets/icons/calender-icon.png";
import {Link} from "react-router-dom";
import MobileHamburgerMenu from "../mobile-hamburger-menu/MobileHamburgerMenu";

function StatsScreenHeader(props) {

    const [liveStatus, setLiveStatus] = useState(window.setLiveStatus || false);
    const [shownDateStatus, setShownDateStatus] = useState(window.setShownDateStatus || false);
    const [shownSearchStatus, setShownSearchStatus] = useState(window.setShownSearchStatus || false);

    useEffect(() => {
        const handleLiveChange = () => {
            setLiveStatus(window.setLiveStatus);
        };
        const handleShownDate = () => {
            setShownDateStatus(window.setShownDateStatus);
        };
        const handleSearch = () => {
            setShownSearchStatus(window.setShownSearchStatus);
        };

        // window'daki toggleChange event'ini dinle
        window.addEventListener('liveChange', handleLiveChange);
        window.addEventListener('shownDateStatus', handleShownDate);
        window.addEventListener('shownSearchStatus', handleSearch);

        // Component unmount olduğunda event listener'ı temizle
        return () => {
            window.removeEventListener('liveChange', handleLiveChange);
            window.removeEventListener('shownDateStatus', handleShownDate);
            window.removeEventListener('shownSearchStatus', handleSearch);
        };
    }, []);

    const liveIconStatus = () => {
        window.setLiveStatus = !window.setLiveStatus; // Değeri değiştir
        window.dispatchEvent(new Event('liveChange')); // Event'i tetikle
    };

    const dateIconStatus = () => {
        window.setShownDateStatus = !window.setShownDateStatus; // Değeri değiştir
        window.dispatchEvent(new Event('shownDateStatus')); // Event'i tetikle
    };

    const searchIconStatus = () => {
        window.setShownSearchStatus = !window.setShownSearchStatus; // Değeri değiştir
        window.dispatchEvent(new Event('shownSearchStatus')); // Event'i tetikle
    };

    return (
        <div className='stats-screen-header-general-container'>
            <MobileHamburgerMenu />
            <div className='stats-screen-header-container'>
                {/*<button onClick={liveIconStatus}>Live</button>*/}
                {
                    liveStatus ?
                        <div style={{position: 'static'}}>
                            <img onClick={liveIconStatus} className='mobile-left-header-icons live-on-icon' src={liveIcon} alt="Live"/>
                        </div>
                        :
                        <div style={{position: 'static'}}>
                            <img onClick={liveIconStatus} className='mobile-left-header-icons' src={liveIcon} alt="Live"/>
                        </div>
                }

                <div className='logo-container'>
                    <Link to={'/'} className="site-logo-text">
                        <h4 style={{margin: 0, color: 'white'}}>FootyAssist</h4>
                    </Link>
                </div>
                <img onClick={dateIconStatus} className='mobile-right-header-icons date-icon' src={calenderIcon} alt="Dates"/>
                <img onClick={searchIconStatus} className='mobile-right-header-icons' src={searchIcon} alt="Search"/>

                {/*<button onClick={dateIconStatus}>Date</button>*/}
            </div>
        </div>
    );
}

export default StatsScreenHeader;
