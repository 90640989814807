import {formatStatsAndType} from "./FormatStatsAndType";

export const liveScoresMeta = {
    tr: {
        title: 'FootyAssist - Futbol Canlı Sonuçlar | İddaa Sonuçları | Canlı Bahis | FootyAssist.com',
        description: 'İddaa, canlı maç sonuçları, iddaa canlı skor, iddaa maç skorları, iddaa programı sonucları.',
        canonical: 'https://www.xfstats.com/',
        meta: {
            charset: 'utf-8',
            name: {
                keywords: 'iddaa, Futbol, Tahmin, Maçlar, Spor, Sahadan, Canlı Sonuçlar, Basketbol, Fenerbahçe, Galatasaray, Beşiktaş, Trabzonspor, Superlig, Transfer, Transferler'
            }
        }
    },
    en: {
        title: 'FootyAssist - Football Live Scores, Latest Football Results | FootyAssist.com',
        description: 'Football live scores page on FootyAssist.com offers all the latest football results from more than 2200+ football leagues all around the world including EPL, LaLiga, Serie A, Bundesliga, UEFA Champions League and more.',
        canonical: 'https://www.xfstats.com/',
        meta: {
            charset: 'utf-8',
            name: {
                keywords: 'Football, livescore, live scores, results'
            }
        }
    }
}

export const statsMeta = {
    tr: {
        title: 'FootyAssist - Football Stats, Tables & Results | Soccer Stats | Live Scores',
        description: 'FootyAssist - İstatistikler hakkında tüm bilgi Descriptions',
        canonical: 'https://www.xfstats.com/stats',
        meta: {
            charset: 'utf-8',
            name: {
                keywords: 'react,meta,document,html,tags'
            }
        }
    },
    en: {
        title: 'FootyAssist - Football Stats, Tables & Results | Soccer Stats | Live Scores',
        description: 'FootyAssist - All information about statistics Descriptions',
        canonical: 'https://www.xfstats.com/stats',
        meta: {
            charset: 'utf-8',
            name: {
                keywords: 'react,meta,document,html,tags'
            }
        }
    }
}

export const bestTeamsMeta = (stats, type) => {
    return {
        tr: {
            title: `FootyAssist - 1500'den fazla lig için ${formatStatsAndType(type)} Oranından Fazla ${formatStatsAndType(stats)} İstatistikleri. En yüksek ve en düşük ${formatStatsAndType(stats)} yüzdesi ${formatStatsAndType(type)} olan Takımların, Liglerin ve Maçların listesi.`,
            description: 'Dünyanın en kapsamlı Futbol İstatistikleri / Futbol İstatistikleri sitesi. Üst/Alt, Kornerler, Maç H2H, Sıralamalar, Sonuçlar, HT/FT, Goller, En Çok Gol Atanlar, Oyun İçi ve daha fazlası. Dünya genelinde 1000\'den fazla futbol müsabakasını kapsıyoruz.',
            canonical: 'https://www.xfstats.com/best-teams',
            meta: {
                charset: 'utf-8',
                name: {
                    keywords: 'Üst/Alt, Kornerler, Maç H2H, Sıralamalar, Sonuçlar, HT/FT, Goller, En Çok Gol Atanlar, Oyun İçi'
                }
            }
        },
        en: {
            title: `FootyAssist - ${formatStatsAndType(type)} ${formatStatsAndType(stats)} Statistics for 1500+ leagues. List of Teams, Leagues, and Matches with the highest and lowest ${formatStatsAndType(type)} ${formatStatsAndType(stats)} %.`,
            description: 'World\'s most in-depth Football Stats / Soccer Stats site. Over/Under, Corners, Match H2H, Tables, Results, HT/FT, Goals, Top Scorers, In-Play & more. We cover 1000+ soccer competitions across the globe',
            canonical: 'https://www.xfstats.com/best-teams',
            meta: {
                charset: 'utf-8',
                name: {
                    keywords: 'Over/Under, Corners, Match H2H, Tables, Results, HT/FT, Goals, Top Scorers, In-Play'
                }
            }
        }
    }
}

export const trendMatchesMeta = (stats, type) => {
    return {
        tr: {
            title: `FootyAssist - 1500'den fazla lig için ${formatStatsAndType(type)} Oranından Fazla ${formatStatsAndType(stats)} İstatistikleri. En yüksek ve en düşük ${formatStatsAndType(stats)} yüzdesi ${formatStatsAndType(type)} olan Takımların, Liglerin ve Maçların listesi.`,
            description: 'Dünyanın en kapsamlı Futbol İstatistikleri / Futbol İstatistikleri sitesi. Üst/Alt, Kornerler, Maç H2H, Sıralamalar, Sonuçlar, HT/FT, Goller, En Çok Gol Atanlar, Oyun İçi ve daha fazlası. Dünya genelinde 1000\'den fazla futbol müsabakasını kapsıyoruz.',
            canonical: 'https://www.xfstats.com/best-teams',
            meta: {
                charset: 'utf-8',
                name: {
                    keywords: 'Üst/Alt, Kornerler, Maç H2H, Sıralamalar, Sonuçlar, HT/FT, Goller, En Çok Gol Atanlar, Oyun İçi'
                }
            }
        },
        en: {
            title: `FootyAssist - ${formatStatsAndType(type)} ${formatStatsAndType(stats)} Statistics for 1500+ leagues. List of Teams, Leagues, and Matches with the highest and lowest ${formatStatsAndType(type)} ${formatStatsAndType(stats)} %.`,
            description: 'World\'s most in-depth Football Stats / Soccer Stats site. Over/Under, Corners, Match H2H, Tables, Results, HT/FT, Goals, Top Scorers, In-Play & more. We cover 1000+ soccer competitions across the globe',
            canonical: 'https://www.xfstats.com/best-teams',
            meta: {
                charset: 'utf-8',
                name: {
                    keywords: 'Over/Under, Corners, Match H2H, Tables, Results, HT/FT, Goals, Top Scorers, In-Play'
                }
            }
        }
    }
}

export const scoreRadarMeta = {
    tr: {
        title: 'FootyAssist - Football Stats, Tables & Results | Soccer Stats | Live Scores',
        description: 'FootyAssist - SkorRadar hakkında tüm bilgi Descriptions',
        canonical: 'https://www.xfstats.com/scoreradar',
        meta: {
            charset: 'utf-8',
            name: {
                keywords: 'react,meta,document,html,tags'
            }
        }
    },
    en: {
        title: 'FootyAssist - Football Stats, Tables & Results | Soccer Stats | Live Scores',
        description: 'FootyAssist - All information about ScoreRadar Descriptions',
        canonical: 'https://www.xfstats.com/scoreradar',
        meta: {
            charset: 'utf-8',
            name: {
                keywords: 'react,meta,document,html,tags'
            }
        }
    }
}

export const tvGuideMeta = {
    tr: {
        title: 'FootyAssist - Football Stats, Tables & Results | Soccer Stats | Live Scores',
        description: 'FootyAssist - Maç TV ekranları Descriptions',
        canonical: 'https://www.xfstats.com/tv',
        meta: {
            charset: 'utf-8',
            name: {
                keywords: 'react,meta,document,html,tags'
            }
        }
    },
    en: {
        title: 'FootyAssist - Football Stats, Tables & Results | Soccer Stats | Live Scores',
        description: 'FootyAssist - Match TV screens Descriptions',
        canonical: 'https://www.xfstats.com/tv',
        meta: {
            charset: 'utf-8',
            name: {
                keywords: 'react,meta,document,html,tags'
            }
        }
    }
}

export const teamDetailsMeta = (teamName, teamId) => {
    return {
        tr: {
            title: `FootyAssist - ${teamName} hakkında tüm bilgi`,
            description: `FootyAssist - ${teamName} hakkında tüm bilgi Descriptions`,
            canonical: `https://www.xfstats.com/team-detail/${teamId}`,
            meta: {
                charset: 'utf-8',
                name: {
                    keywords: 'react,meta,document,html,tags'
                }
            }
        },
        en: {
            title: `FootyAssist - All information about ${teamName}`,
            description: `FootyAssist - All information about ${teamName} Descriptions`,
            canonical: `https://www.xfstats.com/team-detail/${teamId}`,
            meta: {
                charset: 'utf-8',
                name: {
                    keywords: 'react,meta,document,html,tags'
                }
            }
        }
    }
}

export const matchDetailsMeta = (matchDetail) => {
    return {
        tr: {
            title: `FootyAssist - ${matchDetail?.home} - ${matchDetail?.away} maçı detayları`,
            description: `FootyAssist - ${matchDetail?.home} - ${matchDetail?.away} maçı detayları Descriptions`,
            canonical: `https://www.xfstats.com/match-detail/${matchDetail?.id}`,
            meta: {
                charset: 'utf-8',
                name: {
                    keywords: 'react,meta,document,html,tags'
                }
            }
        },
        en: {
            title: `FootyAssist - ${matchDetail?.home} - ${matchDetail?.away} match details`,
            description: `FootyAssist - ${matchDetail?.home} - ${matchDetail?.away} match details Descriptions`,
            canonical: `https://www.xfstats.com/match-detail/${matchDetail?.id}`,
            meta: {
                charset: 'utf-8',
                name: {
                    keywords: 'react,meta,document,html,tags'
                }
            }
        }
    }
}

export const leagueDetailsMeta = (league) => {
    return {
        tr: {
            title: `FootyAssist - ${league?.league} hakkında tüm bilgi`,
            description: `FootyAssist - ${league?.league} hakkında tüm bilgi Descriptions`,
            canonical: `https://www.xfstats.com/league-details/${league?.seasonId}/${league.leagueId}`,
            meta: {
                charset: 'utf-8',
                name: {
                    keywords: 'react,meta,document,html,tags'
                }
            }
        },
        en: {
            title: `FootyAssist - All information about ${league?.league}`,
            description: `FootyAssist - All information about ${league?.league} Descriptions`,
            canonical: `https://www.xfstats.com/league-details/${league?.seasonId}/${league.leagueId}`,
            meta: {
                charset: 'utf-8',
                name: {
                    keywords: 'react,meta,document,html,tags'
                }
            }
        }
    }
}
