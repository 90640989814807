import React, {useContext} from 'react';
import './Auth.css'
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Container from "@mui/material/Container";
import {AppContext} from "../../context/AppContext";
import {Alert, AlertTitle} from "@mui/material";
import {useSearchParams} from "react-router-dom";
import {i18n} from "../../i18n/i18n";

function SignIn(props) {

    const {login, loginError, i18String} = useContext(AppContext);

    const [searchParams, setSearchParams] = useSearchParams()

    const redirect = searchParams.get('redirect');

    return (
        <div className={'general-auth-section'}>
            <Container component="main" maxWidth="sm">
                <Box
                    sx={{
                        boxShadow: 3,
                        borderRadius: 2,
                        px: 4,
                        py: 6,
                        marginTop: 8,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                >
                    <Typography>
                        {
                            loginError &&
                            <Alert severity="error">
                                <AlertTitle>{i18String('Login Error')}</AlertTitle>
                                <strong>{i18String('Check username or password')}</strong>
                            </Alert>
                        }
                        {

                            loginError === false &&
                            <Alert severity="success">
                                <AlertTitle>{i18String('Login Success')}</AlertTitle>
                                <strong>{i18String('Login Success')}</strong>
                            </Alert>
                        }
                    </Typography>
                    <Typography component="h1" variant="h5">
                        <div>
                            <span className='auth-text'>{i18String('Login')}</span>
                        </div>
                    </Typography>
                    <Box component="form" onSubmit={e => login(e, redirect)} noValidate sx={{mt: 1}}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="username"
                            label={`${i18String('Please enter User Name')}`}
                            name="username"
                            autoComplete="username"
                            autoFocus
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label={`${i18String('Please enter Password')}`}
                            type="password"
                            id="password"
                            autoComplete="password"
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{mt: 3, mb: 2}}
                            style={{background: 'var(--H3Dark3)'}}
                        >
                            <span>{i18String('Login')}</span>
                        </Button>
                        <Grid container>
                            <Grid item xs>
                                <Link href="#" variant="body2" className='auth-text'>
                                    <span>{i18String('Forgot Password')}</span>
                                </Link>
                            </Grid>
                            <Grid item>
                                <Link href="sign-up" variant="body2" className='auth-text'>
                                    {i18String('Register')}
                                </Link>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Container>
        </div>
    );
}

export default SignIn;
