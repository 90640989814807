import React, {useContext, useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import {fetchResults} from "../../../api";
import {AppContext} from "../../../context/AppContext";

function Results(props) {
    const {i18String} = useContext(AppContext);

    const params = useParams();

    const [resultsData, setResultsData] = useState(null);

    useEffect(() => {
        fetchResults(params.seasonId).then(data => {
            setResultsData(data.data.data);
        })
    }, [params.seasonId]);

    if (!resultsData) {
        return (
            <div className="loading-container">
                <div>
                    <div className="loading blink"></div>
                    <div>
                        Loading...
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div style={{width: '100%'}}>
            {
                resultsData &&
                resultsData.map(rounds => {
                    return (
                        <div>
                            <div className='rounds-container'>
                                <span className='rounds-text'>{rounds.round}. {i18String('Week')}</span>
                            </div>
                            <div>
                                {
                                    rounds.matches.map(match => {
                                        return (
                                            <div>
                                                <table style={{width: '100%', borderSpacing: 0}}>
                                                    <tr className='results-table-row-container'>

                                                        <div>
                                                            <div className='results-date-container'>
                                                                {match.date}
                                                            </div>
                                                        </div>

                                                        <div className="card-container">
                                                            {match.hred > 0 &&
                                                                <div className="match-card red-card">{match.hred}</div>}
                                                        </div>

                                                        <div className='results-home-container'>
                                                            <span className='results-team-name'>{match.home}</span>
                                                        </div>

                                                        <div className='results-score'>
                                                            {match.hscore} - {match.ascore}
                                                        </div>

                                                        <div className='results-away-container'>
                                                            <span className='results-team-name'>{match.away}</span>
                                                        </div>

                                                        <div className="card-container">
                                                            {match.ared > 0 &&
                                                                <div className="match-card red-card">{match.ared}</div>}
                                                        </div>

                                                        <div className='results-ht-score'>
                                                            HT: {match.hp1} - {match.ap1}
                                                        </div>
                                                    </tr>
                                                </table>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    )
                })
            }
        </div>
    );
}

export default Results;
