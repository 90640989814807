import React, {useContext, useEffect} from 'react';
import cancelImg from "../../assets/icons/cancel.png";
import {Link, useNavigate} from "react-router-dom";
import {AppContext} from "../../context/AppContext";
function PaymentCancel(props) {
    const {i18String} = useContext(AppContext);

    const navigate = useNavigate();
    useEffect(() => {
        const interval = setInterval(() => {
            navigate('/');
        }, 5000);
        return () => clearInterval(interval);
    },[])

    return (
        <div className='const-width'>
            <div className='payment-section'>
                <div className='success-message-container'>
                    <div className='success-text-and-img-container'>
                        <img className='success-check-icon' src={cancelImg} alt="Success"/>
                        <span className='success-text'>{i18String('An unexpected error has occurred. Please try again or contact us.')}</span>
                    </div>

                    <div className='go-home-page-button-container'>
                        <Link className='go-home-page-button' to={'/'}>{i18String('Go Home Page')}</Link>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PaymentCancel;
