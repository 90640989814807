import React, {useState} from 'react';
import StatsHeader from "../stats/StatsHeader";
import LiveScoresMatches from "./LiveScoresMatches";
import LiveScoresTableHeader from "./LiveScoresTableHeader";

function LiveScoresLeagueAndMatchesTable(props) {

    const {league, matches, favMatches} = props;

    const [statsHeader, setStatsHeader] = useState({
        liveScoresTableHeader: [
            {name: 'İY', text: 'İY'},
            {name: 'K', text: ''},
            {name: '1', text: '1'},
            {name: 'X', text: 'X'},
            {name: '2', text: '2'},
            {name: '2.5O', text: '2.5O'},
            {name: '2.5U', text: '2.5U'},
        ]
    })

    return (
        <div className="live-scores-cards-container">
            <table className="live-scores-table-section stats-table-header">
                <tbody>
                <tr className="live-scores-league-name-container">
                    {
                        <td className="league-name-and-flag-container">
                            {
                                favMatches && favMatches.length > 0 ?
                                    <span className="table-12px-fav-header">Favorites</span> :
                                    <div
                                        onClick={() => {
                                            window.open(`/league/${league.title.area.toLowerCase()}-${league.title.league.toLowerCase().replace(/\s+/g, '-')}/${league.title.leagueId}/${league.title.seasonId}`)
                                        }}
                                        className={'league-name-and-flag'}
                                    >
                                        <img className="country-flag"
                                             src={`https://d17jyjgv9729qk.cloudfront.net/assets/img/m/countries/${league.title.areaId}.png`}
                                             alt="country"/>
                                        <span
                                            className="live-scores-area-info-container">{league.title.area} - {league.title.league}</span>
                                    </div>
                            }

                        </td>
                    }
                    {
                        statsHeader['liveScoresTableHeader'].map((stHeader) => {
                            return (
                                <LiveScoresTableHeader key={stHeader.name} stHeader={stHeader}/>
                            )
                        })
                    }
                </tr>
                </tbody>
            </table>
            <LiveScoresMatches league={league} matches={matches}></LiveScoresMatches>
        </div>
    );
}

export default LiveScoresLeagueAndMatchesTable;
