import React, {useContext, useState} from 'react';
import favIcon from '../../assets/icons/fav-on.svg'
import favOff from '../../assets/icons/fav-off.svg'
import dayjs from "dayjs";
import {Link} from "react-router-dom";
import StatsRow from "./StatsRow";
import StatsRowNoInfo from "./StatsRowNoInfo";
import {AppContext} from "../../context/AppContext";

function Matches(props) {
    const {addAndRemoveFavMatches, isFav, lang, timeZone} = useContext(AppContext);
    const {index, favMatches, matches, statsType, league, selectedTypeForSelect} = props;

    const startTime = (value) => {
        return dayjs(value).tz(timeZone).format("HH:mm");
    }

    if (favMatches?.length === 0) {
        return (
            <div className='no-matches-that-match-filter'>
                {
                    lang === 'en' ?
                        <span>There are no matches among your favorite matches that match the filter you want!</span>
                        :
                        <span>Favori maçlar arasında istediğiniz filtreye uygun maç yok!</span>
                }
            </div>
        )
    }

    return (
        <div>
            {
                matches.map((match) => {
                    return (
                        <div key={match.id}>
                            {/*WEB*/}
                            <div className="matches-container match-team-names">
                                <table className="fixtures-table-section">
                                    <tbody>
                                    <tr className="fixture-table-row">
                                        <td className="match-info-td">
                                            <div className={statsType === 'avgStats' ? 'match-info-container-for-avg' : 'match-info-container'}>
                                                <div>
                                                    {
                                                        isFav(match.id) ?
                                                            <img
                                                                onClick={() => addAndRemoveFavMatches(league, match)}
                                                                className="fav-icon" src={favIcon} alt="fav"/> :
                                                            <img
                                                                onClick={() => addAndRemoveFavMatches(league, match)}
                                                                className="fav-icon" src={favOff} alt="fav"/>
                                                    }
                                                </div>

                                                <div>
                                                    {(match.status === 'Abandoned' || match.status === 'Suspended') && (
                                                        <div>
                                                            {lang === 'en' ? <span className='abnd-match'>Abnd.</span> :
                                                                <span className='abnd-match'>Yrdk</span>}
                                                        </div>
                                                    )}
                                                    {match.status === 'Fixture' &&
                                                        <span>{startTime(match.dateTimeUtc)}</span>}
                                                    {match.status === 'Played' && <span>MS</span>}
                                                    {match.status === 'Playing' && match.st !== 'HT' &&
                                                        <span className="live-match-min">{match.min}'</span>}
                                                    {match.st === 'HT' && <span className="live-match-min">HT</span>}
                                                    {match.status === 'Postponed' &&
                                                        <div>
                                                            {
                                                                lang === 'en' ?
                                                                    <span className="live-match-min">Post.</span>
                                                                    :
                                                                    <span className="live-match-min">Ert.</span>
                                                            }
                                                        </div>
                                                    }
                                                    {match.status === 'Cancelled' &&
                                                        <div>
                                                            {
                                                                lang === 'en' ?
                                                                    <span className="live-match-min">Canc.</span>
                                                                    :
                                                                    <span className="live-match-min">İptl.</span>
                                                            }
                                                        </div>
                                                    }
                                                </div>

                                                <div className="card-container">
                                                    {match.hred > 0 &&
                                                        <div className="match-card red-card">{match.hred}</div>}
                                                </div>

                                                <span
                                                    onClick={() => {
                                                        window.open(`/team-detail/${match.homeId}`)
                                                    }}
                                                    className="match-team-name"
                                                >
                                                    {match.home}
                                                </span>
                                                <div
                                                    className="score-container"
                                                    onClick={() => {
                                                        localStorage.setItem(`teams-state:${match.id}`, JSON.stringify(
                                                            {
                                                                home: match.home,
                                                                homeId: match.homeId,
                                                                away: match.away,
                                                                awayId: match.awayId,
                                                                time: match.dateTimeUtc,
                                                                seasonId: match.seasonId
                                                            }
                                                        ))
                                                        window.open(`/match-detail/${match.id}`);
                                                    }}
                                                >
                                                    {match.status === 'Playing' &&
                                                        <span><span
                                                            className="live-match-score">{match.hscore} -</span>  <span
                                                            className="live-match-score">{match.ascore}</span></span>}
                                                    {match.status === 'Played' &&
                                                        <span
                                                            style={{
                                                                color: 'var(--Black01)',
                                                                fontWeight: '600'
                                                            }}>{match.hscore} - {match.ascore}</span>}
                                                    {match.status === 'Fixture' ||
                                                    match.status === 'Abandoned' ||
                                                    match.status === 'Postponed' ||
                                                    match.status === 'Suspended' ||
                                                    match.status === 'None' ||
                                                    match.status === 'Cancelled'
                                                        ?
                                                        <span className='versus-text'>-</span>
                                                            :
                                                        null
                                                    }
                                                    {(match.status === 'Abandoned' || match.status === 'Suspended') &&
                                                        <span><span
                                                            className="live-match-score">{match.hscore} -</span>  <span
                                                            className="live-match-score">{match.ascore}</span></span>}
                                                </div>
                                                <span
                                                    onClick={() => {
                                                        window.open(`/team-detail/${match.awayId}`)
                                                    }}
                                                    className="match-team-name away-team-name"
                                                >
                                                    {match.away}
                                                </span>

                                                <div className="card-container">
                                                    {match.ared > 0 &&
                                                        <div className="match-card red-card">{match.ared}</div>}
                                                </div>

                                                <div>
                                                    {
                                                        match.status !== 'Fixture' &&
                                                        <span
                                                            className='live-scores-1-half'>{match.hp1} - {match.ap1}</span>
                                                    }
                                                </div>
                                            </div>
                                        </td>
                                        {
                                            statsType !== 'avgStats' && (
                                                match['avgStats'] ? (
                                                    match['avgStats'].map((stat) => (
                                                        <StatsRow
                                                            index={index}
                                                            key={stat.name}
                                                            stat={stat}
                                                            statsType={'avgStats'}
                                                        />
                                                    ))
                                                ) : (
                                                    <StatsRowNoInfo borderStyle={'no-info-stats-for-avg'} />
                                                )
                                            )
                                        }
                                        {
                                            match[statsType] ?
                                                match[statsType].map((stat) => {
                                                    return (
                                                        <StatsRow
                                                            index={index}
                                                            key={stat.name} /*borderStyle={statStyle(stat)}*/
                                                            stat={stat}
                                                            statsType={statsType}/>
                                                    )
                                                }) :
                                                <StatsRowNoInfo borderStyle={'no-info-stats'}/>
                                        }
                                    </tr>
                                    </tbody>
                                </table>
                            </div>

                            {/*MOBILE*/}
                            <div className="fixtures-section-mobile match-team-names">
                                <table className="fixtures-table-section">
                                    <tbody>
                                    <tr className="fixture-table-row">
                                        <td className="match-info-td">
                                            <div className="match-info-container">
                                                <div>
                                                    {
                                                        isFav(match.id) ?
                                                            <img
                                                                onClick={() => addAndRemoveFavMatches(league, match)}
                                                                className="fav-icon" src={favIcon} alt="fav"/> :
                                                            <img
                                                                onClick={() => addAndRemoveFavMatches(league, match)}
                                                                className="fav-icon" src={favOff} alt="fav"/>
                                                    }
                                                </div>

                                                <div>
                                                    {(match.status === 'Abandoned' || match.status === 'Suspended') && (
                                                        <div>
                                                            {lang === 'en' ? <span className='abnd-match'>Abnd.</span> :
                                                                <span className='abnd-match'>Yrdk</span>}
                                                        </div>
                                                    )}
                                                    {match.status === 'Fixture' &&
                                                        <span>{startTime(match.dateTimeUtc)}</span>}
                                                    {match.status === 'Played' && <span>MS</span>}
                                                    {match.status === 'Playing' && match.st !== 'HT' &&
                                                        <span className="live-match-min">{match.min}'</span>}
                                                    {match.st === 'HT' && <span className="live-match-min">HT</span>}
                                                    {match.status === 'Cancelled' &&
                                                        <div>
                                                            {
                                                                lang === 'en' ?
                                                                    <span className="live-match-min">Canc.</span>
                                                                    :
                                                                    <span className="live-match-min">İptl.</span>
                                                            }
                                                        </div>
                                                    }
                                                </div>

                                                <div className="card-container">
                                                    {match.hred > 0 &&
                                                        <div className="match-card red-card">{match.hred}</div>}
                                                </div>

                                                <span
                                                    onClick={() => {
                                                        window.open(`/team-detail/${match.homeId}`)
                                                    }}
                                                    className="match-team-name"
                                                >
                                                    {match.home}
                                                </span>
                                                <div className="score-container"
                                                     onClick={() => {
                                                         localStorage.setItem(`teams-state:${match.id}`, JSON.stringify(
                                                             {
                                                                 home: match.home,
                                                                 homeId: match.homeId,
                                                                 away: match.away,
                                                                 awayId: match.awayId,
                                                                 time: match.dateTimeUtc,
                                                                 seasonId: match.seasonId
                                                             }
                                                         ))
                                                         window.open(`/match-detail/${match.id}`);
                                                     }}
                                                >
                                                    {match.status === 'Playing' &&
                                                        <span><span
                                                            style={{color: 'var(--H3Red01)'}}>{match.hscore} -</span>  <span
                                                            style={{color: 'var(--H3Red01)'}}>{match.ascore}</span></span>}
                                                    {match.status === 'Played' &&
                                                        <span
                                                            style={{
                                                                color: 'black',
                                                                fontWeight: '600'
                                                            }}>{match.hscore} - {match.ascore}</span>}

                                                    {match.status === 'Fixture' ||
                                                    match.status === 'Abandoned' ||
                                                    match.status === 'Postponed' ||
                                                    match.status === 'Suspended' ||
                                                    match.status === 'None' ||
                                                    match.status === 'Cancelled'
                                                        ?
                                                        <span className='versus-text'>-</span>
                                                        :
                                                        null
                                                    }
                                                    {(match.status === 'Abandoned' || match.status === 'Suspended') &&
                                                        <span><span
                                                            className="live-match-score">{match.hscore} -</span>  <span
                                                            className="live-match-score">{match.ascore}</span></span>}
                                                </div>
                                                <span
                                                    onClick={() => {
                                                        window.open(`/team-detail/${match.awayId}`)
                                                    }}
                                                    className="match-team-name away-team-name"
                                                >
                                                    {match.away}
                                                </span>

                                                <div className="card-container">
                                                    {match.ared > 0 &&
                                                        <div className="match-card red-card">{match.ared}</div>}
                                                </div>
                                                <div></div>
                                                <div></div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr style={{display: 'flex', background: '#f8f8f8'}}>
                                        {
                                            match[statsType] ?
                                                match[statsType].map((stat) => {
                                                    return (
                                                        <StatsRow
                                                            index={index}
                                                            key={stat.name}
                                                            stat={stat}
                                                            statsType={statsType}
                                                        />
                                                    )
                                                }) :
                                                <StatsRowNoInfo borderStyle={'no-info-stats'}/>
                                        }
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    )
                })
            }
        </div>
    );
}

export default Matches;
